import React, { useEffect, useState } from 'react';
import { UserService } from 'api/UserService';
import { Form } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { InputForm, Notification } from 'components/common';
import { HOME_PAGE } from 'global/routes';
import { fetchUser } from 'redux/action/userAction';
import Cookies from 'universal-cookie';
import md5Hex from 'md5-hex';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import logo from 'assets/images/logo_vnpt_login.png';

const CryptoJS = require('crypto-js');

const cookies = new Cookies();
const d = new Date();
const day = 1 * 86400 * 1000;

const KEY_SECRET = 'VNPTMEDIA@57';

function generateId() {
  const date = moment(Date.now()).format('DD-MM-YYYY HH:mm:ss:ms');
  return CryptoJS.AES.encrypt(date, KEY_SECRET).toString();
}

const Login = () => {
  const [deviceId, setDeviceId] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const redirectUrl = new URLSearchParams(location.search).get('redirectUrl');

  useEffect(() => {
    if (!localStorage.getItem('deviceId')) {
      const id = generateId();
      localStorage.setItem('deviceId', id);
      setDeviceId(id);
    } else {
      setDeviceId(localStorage.getItem('deviceId'));
    }
  }, []);

  const handleLogin = (values) => {
    const encodePw = md5Hex(`${values.password}_Cem@2021`);
    const data = {
      email: values.email,
      password: encodePw,
      deviceId,
    };
    UserService.login(data, (res) => {
      if (res.code === 0) {
        Notification.success('Đăng nhập thành công');
        d.setTime(d.getTime() + day);
        cookies.set('token', res.data.token, { path: HOME_PAGE, expires: d });
        cookies.set('email', res.data.email, { path: HOME_PAGE, expires: d });
        cookies.set('idUser', res.data.id, { path: HOME_PAGE, expires: d });
        // localStorage.setItem('email', values.email);
        dispatch(fetchUser({ email: values.email }));
        // history.push(HOME_PAGE);
        history.push(redirectUrl || HOME_PAGE);
      } else {
        Notification.error(res.message);
      }
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto" src={logo} alt="Workflow" style={{ width: '150px' }} />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Đăng nhập hệ thống</h2>
        </div>
        <Form name="basic" className="mt-8 space-y-6" onFinish={handleLogin}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <InputForm isEmail isRequired name="email" placeholder="Email đăng nhập" />
            </div>
            <div>
              <InputForm isPassword isRequired name="password" placeholder="Mật khẩu" />
            </div>
          </div>

          {/* <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Form.Item
                name="remember"
                valuePropName="checked"
                className="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              >
                <Checkbox className="w-32">Ghi nhớ tôi</Checkbox>
              </Form.Item>
            </div>

            <div className="text-sm">
              <Link to={FORGOT_PASSWORD} className="font-medium text-sm text-indigo-600 hover:text-indigo-500">
                <a style={{ color: '#0066b4' }}>Quên mật khẩu?</a>
              </Link>
            </div>
          </div> */}

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm
               font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2
               focus:ring-indigo-500"
            >
              Đăng nhập
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default React.memo(Login);
